import { DatePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import {
	Component,
	ContentChild,
	Input,
	OnDestroy,
	TemplateRef,
} from '@angular/core';
import { Subscription, skipWhile } from 'rxjs';
import { DynamicLocaleService } from 'src/app/core/services/dynamic-locale.service';
import { PharmacySettingsService } from 'src/app/core/services/pharmacy-settings.service';
import { PharmacySettingsDto } from '../../../swagger/model/pharmacySettingsDto';

@Component({
    selector: 'app-pdf-scaffold',
    template: `
		<div
			class="flex flex-col justify-between px-4 bg-white max-w-[210mm] w-[210mm] max-h-[297mm] h-[297mm]"
		>
			<div class="flex-shrink header">
				<div
					class="flex justify-between px-4 pt-4 pb-3 border-b border-black border-solid"
				>
					<div class="space-y-1">
						<div class="text-xl">
							{{ settings?.name }}
						</div>
						@if (title) {
							<div>
								{{ title }}
							</div>
						}
					</div>
					<div>
						{{ now | date: 'short' : '' : locale }}
					</div>
				</div>
			</div>
			<div class="flex-grow body">
				<ng-container [ngTemplateOutlet]="body" />
			</div>
			<div class="flex-shrink footer">
				<div
					class="flex justify-between items-start px-4 pt-3 pb-4 border-t border-black border-solid"
				>
					<div class="flex-grow">
						{{ settings?.name }}
					</div>
					<div class="flex-grow">
						<div [ngClass]="{ 'mt-[8px]': marginCheckbox }">
							<svg
								class="h-4"
								width="133"
								height="24"
								viewBox="0 0 133 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clip-path="url(#clip0_119_95)">
									<path
										d="M70.2811 20.242C70.2811 20.5713 70.1297 20.7472 69.8024 20.7472H68.4381C68.0842 20.7472 67.9329 20.6204 67.9329 20.242V3.92928C67.9329 3.49972 68.1374 3.34836 68.4626 3.34836H72.075C75.8366 3.34836 78.4385 4.63497 78.4385 8.44979C78.4385 10.8737 76.8982 12.5899 74.9039 13.273L79.0439 20.1909C79.1953 20.4445 79.1462 20.7472 78.7167 20.7472H77.2848C76.9538 20.7774 76.6398 20.5954 76.5014 20.2931L72.705 13.6719H70.2811V20.242ZM70.2811 11.9087H72.2263C74.4477 11.9087 76.0637 10.7223 76.0637 8.47434C76.0637 5.9993 74.4989 5.1402 71.897 5.1402H70.2811V11.9087Z"
										fill="black"
									/>
									<path
										d="M86.42 20.9988C82.505 20.9988 80.7622 18.2211 80.7622 14.6108C80.7622 11.0496 82.8077 8.26981 86.5714 8.26981C90.4844 8.26981 92.2537 10.9719 92.2537 14.6333C92.2476 18.1965 90.1817 20.9988 86.42 20.9988ZM86.5448 9.93892C83.8182 9.93892 82.9856 12.3383 82.9856 14.5597C82.9856 16.8833 83.767 19.3338 86.4446 19.3338C89.1221 19.3338 90.0303 17.0102 90.0303 14.6619C90.0303 12.3137 89.1794 9.93892 86.5448 9.93892Z"
										fill="black"
									/>
									<path
										d="M105.662 18.2722L108.037 8.97958C108.094 8.69597 108.364 8.50721 108.65 8.55002H109.839C110.141 8.55002 110.248 8.72594 110.141 9.05526L106.807 20.2931C106.74 20.5735 106.481 20.7651 106.194 20.7472H104.829C104.535 20.79 104.261 20.5873 104.216 20.2931L101.816 11.7328H101.792L99.4681 20.3238C99.3961 20.6061 99.125 20.7903 98.8361 20.7534H97.4983C97.2091 20.7765 96.9471 20.5827 96.8847 20.2993L93.5526 9.0614C93.4769 8.80776 93.526 8.55616 93.931 8.55616H95.2442C95.6226 8.55616 95.8006 8.68298 95.8578 8.91003L98.2572 18.2538H98.3083L100.884 8.93458C100.928 8.69631 101.148 8.53184 101.389 8.55616H102.526C102.753 8.55616 102.905 8.6073 102.98 8.85889L105.607 18.2783L105.662 18.2722Z"
										fill="black"
									/>
									<path
										d="M120.007 18.8777C119.426 19.9904 117.757 20.9988 116.219 20.9988C112.741 20.9988 111.195 18.0452 111.195 14.8624C111.195 10.6467 113.694 8.27185 116.572 8.27185C117.837 8.27185 119.199 8.80163 119.958 9.8121L119.982 9.00413C119.982 8.7014 120.134 8.55004 120.463 8.55004H121.574C121.707 8.52436 121.845 8.56674 121.941 8.66312C122.037 8.7595 122.079 8.89727 122.052 9.03072V17.8693C122.052 18.802 122.079 19.2316 122.609 19.2316H122.709C123.012 19.2316 123.138 19.3338 123.138 19.61V20.3688C123.138 20.647 123.012 20.7473 122.709 20.7473H121.702C120.592 20.7473 120.066 19.8882 120.066 18.8777H120.007ZM119.907 11.8085C119.292 10.6936 118.123 9.99804 116.851 9.99006C114.905 9.99006 113.441 11.7062 113.441 14.711C113.441 17.4888 114.729 19.2111 116.548 19.2111C118.164 19.2111 119.452 17.9224 119.907 16.6604V11.8085Z"
										fill="black"
									/>
									<path
										d="M126.638 3.91291C126.62 4.06632 126.524 4.10519 126.389 4.10519H125.084V8.50093C125.084 8.65434 125.026 8.73002 124.855 8.73002H124.335C124.163 8.73002 124.106 8.65434 124.106 8.50093V4.10519H122.78C122.627 4.10519 122.576 4.04587 122.576 3.91291V3.52836C122.576 3.39336 122.633 3.33608 122.78 3.33608H126.505C126.659 3.33608 126.71 3.37495 126.698 3.52836L126.638 3.91291ZM130.592 8.5582C130.565 8.66985 130.459 8.74363 130.345 8.73002H130.075C129.96 8.74474 129.852 8.67072 129.825 8.5582L128.348 5.12179L128.309 8.50093C128.309 8.65434 128.252 8.73002 128.078 8.73002H127.634C127.463 8.73002 127.405 8.65434 127.405 8.50093L127.538 3.56722C127.538 3.39336 127.598 3.33608 127.743 3.33608H128.183C128.323 3.31583 128.455 3.40384 128.491 3.54063L130.218 7.47614L131.854 3.54063C131.89 3.40472 132.022 3.31695 132.161 3.33608H132.57C132.724 3.33608 132.775 3.39336 132.775 3.56722L132.949 8.50093C132.949 8.65434 132.891 8.73002 132.717 8.73002H132.218C132.047 8.73002 131.989 8.65434 131.989 8.50093L131.93 5.1402L130.592 8.5582Z"
										fill="black"
									/>
									<path
										d="M38.2059 20.8311H31.7524C31.3804 20.8311 31.0241 20.6816 30.7636 20.4162C30.5032 20.1507 30.3604 19.7916 30.3676 19.4197V4.80271C30.3614 4.43359 30.5053 4.07778 30.7664 3.81674C31.0274 3.55569 31.3832 3.41177 31.7524 3.41792H37.7886C41.1064 3.41792 43.0925 5.34886 43.0925 7.85662C43.0925 10.3644 41.542 11.2971 41.002 11.4914C41.8386 11.696 44.1112 12.6635 44.1112 15.836C44.1112 18.5545 41.9286 20.8311 38.2059 20.8311ZM37.6679 5.98909H33.1678V10.3787H37.6679C39.4556 10.3787 40.2963 9.38459 40.2963 8.1839C40.2963 6.9832 39.5068 5.97886 37.6679 5.97886V5.98909ZM38.3081 12.9131H33.1637V18.2334H38.3081C40.3679 18.2334 41.315 16.8997 41.315 15.5742C41.315 14.2488 40.3823 12.9028 38.3081 12.9028V12.9131ZM52.1561 20.8188H47.8728C47.4965 20.8267 47.1332 20.6808 46.8669 20.4149C46.6005 20.1489 46.4541 19.7858 46.4614 19.4095V4.80271C46.4614 4.43079 46.6109 4.07447 46.8764 3.81399C47.1419 3.55352 47.501 3.41078 47.8728 3.41792H52.1561C57.9632 3.41792 61.1705 7.30434 61.1705 12.1174C61.1705 16.9304 57.9305 20.8188 52.1561 20.8188ZM51.974 5.97886H49.2924V18.2518H51.9842C56.0936 18.2518 58.3846 15.8217 58.3846 12.1153C58.3846 8.4089 56.1243 5.97886 51.9761 5.97886H51.974Z"
										fill="black"
									/>
									<path
										d="M6.88134 11.4628L0.272381 13.7128C-0.0358802 11.5225 0.281075 9.28963 1.18671 7.27161L6.91407 11.3073C6.94884 11.3339 6.96316 11.3564 6.96316 11.381C6.96316 11.4055 6.92838 11.4464 6.88747 11.4587L6.88134 11.4628ZM11.813 0.376282C9.59507 0.38085 7.42471 1.01961 5.55791 2.21722L10.3587 7.2982C10.3914 7.33297 10.4139 7.35138 10.4425 7.35138C10.4712 7.35138 10.5039 7.33297 10.5141 7.27774C10.5755 6.95865 11.813 0.376282 11.813 0.376282ZM8.13113 8.81186C8.13093 8.7919 8.12602 8.77228 8.11681 8.75458C8.10454 8.72185 5.48427 2.26222 5.48427 2.26222C3.62665 3.47147 2.14942 5.18202 1.22353 7.19592C1.22353 7.19592 7.95931 8.8589 8.0084 8.87322C8.02812 8.8752 8.048 8.8752 8.06772 8.87322C8.10048 8.87337 8.12796 8.84855 8.13113 8.81595V8.81186ZM16.8306 11.4628L23.4395 13.7128C23.7475 11.5227 23.4313 9.29008 22.5272 7.27161L16.7999 11.3073C16.7651 11.3339 16.7508 11.3564 16.7508 11.381C16.7508 11.4055 16.7917 11.4505 16.8306 11.4628ZM13.1978 7.27979C13.208 7.33502 13.2387 7.35343 13.2694 7.35343C13.3001 7.35343 13.3205 7.33502 13.3532 7.30024L18.156 2.21722C16.2884 1.01986 14.1174 0.381143 11.8989 0.376282C11.8989 0.376282 13.1364 6.95865 13.1978 7.27979ZM15.6524 8.8814C15.6721 8.88312 15.692 8.88312 15.7117 8.8814C15.7608 8.86708 22.4945 7.20411 22.4945 7.20411C21.5686 5.19128 20.0913 3.48204 18.2338 2.27449C18.2338 2.27449 15.6074 8.72595 15.5951 8.76276C15.5869 8.78083 15.582 8.80024 15.5808 8.82004C15.5829 8.85458 15.6116 8.88147 15.6462 8.8814H15.6524Z"
										fill="black"
									/>
									<path
										d="M11.8601 23.7746C17.6408 23.7748 22.555 19.5525 23.4252 13.8376L19.0663 14.4513C19.0135 14.4549 18.969 14.492 18.9559 14.5433C18.0714 17.102 15.8758 18.9849 13.2122 19.4688C14.5056 18.8391 15.193 17.3998 14.8698 15.998C14.5467 14.5962 13.2986 13.6031 11.8601 13.6031C10.4215 13.6031 9.17345 14.5962 8.85033 15.998C8.52721 17.3998 9.21462 18.8391 10.508 19.4688C7.84436 18.9849 5.64874 17.102 4.7643 14.5433C4.75119 14.492 4.70667 14.4549 4.65385 14.4513L0.294922 13.8376C1.16522 19.5525 6.07933 23.7748 11.8601 23.7746Z"
										fill="black"
									/>
								</g>
								<defs>
									<clipPath id="clip0_119_95">
										<rect
											width="133"
											height="24"
											fill="black"
										/>
									</clipPath>
								</defs>
							</svg>
						</div>
					</div>
					<div class="flex-grow text-right">
						{{ now | date: 'short' : '' : locale }}
					</div>
				</div>
			</div>
		</div>
	`,
    styles: `
		@page {
			size: A4;
			margin: 0;
		}
	`,
    imports: [NgTemplateOutlet, NgClass, DatePipe]
})
export class PdfScaffoldComponent implements OnDestroy {
	@Input() marginCheckbox: boolean = false;
	@Input() title: string = undefined;

	@ContentChild('body', { read: TemplateRef, static: false })
	body: TemplateRef<any>;

	now: Date = new Date();
	locale: string;
	settings: PharmacySettingsDto;

	private subs: Subscription = new Subscription();

	constructor(
		private pharmacySettingsService: PharmacySettingsService,
		private dynamicLocale: DynamicLocaleService,
	) {
		this.subs.add(
			this.pharmacySettingsService.onPharmacySettingsChanged
				.pipe(skipWhile((x) => x == null))
				.subscribe((x) => {
					this.settings = x;
				}),
		);

		this.locale = this.dynamicLocale.getCurRegionSetInStorage();
		this.subs.add(
			this.dynamicLocale.localeChange$.subscribe((x) => {
				this.locale = x;
			}),
		);
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}
}
